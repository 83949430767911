import React, { useState, CSSProperties } from 'react'
import SearchForm from './home/search-form'
import { navigate } from "gatsby"
import { Jumbotron, Container } from 'react-bootstrap'

interface Props {
  title?: string
  subtitle: string
  searchForm?: boolean
  icon?: boolean
  customStyle?: CSSProperties
}

const DEFAULT_STYLE = { padding: '6rem 0' }

const Header = ({ title = "", subtitle, searchForm = false, icon = false, customStyle = DEFAULT_STYLE }: Props): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const onChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    navigate(
      `/search/?q=${searchTerm}`,
      {
        state: { searchTerm },
      }
    )
  }

  return (
    <Jumbotron fluid className="header-bg m-0" style={customStyle}>
      <Container>
        {title && <h1 className="text-center">{title}</h1>}
        <p className="text-center">{subtitle}<br /></p>
        {searchForm && <SearchForm
          searchTerm={searchTerm}
          onChange={onChangeSearchTerm}
          onSubmit={onSubmit}
        />}
        {icon && <a href="#topMediaAgencies" className="header-arrow">
          <img src="/images/arrow-down.svg" />
        </a>
        }
      </Container>
    </Jumbotron>
  )
}

export default Header