import { useIntl, Link } from "gatsby-plugin-intl"
import React, { useState } from "react"
import FAQAccordion from "./faq/faq-acordion"

const FAQRu = (): JSX.Element => {
  const [keys, setKeys] = useState({})
  const intl = useIntl()
  const onClick = (key) => {
    setKeys({ ...keys, [key]: !keys[key] })
  }
  const getClassName = (key) => keys[key] ? "up-icon" : "";
  return (
    <>
      {/* Descriere generală */}
      <FAQAccordion
        eventKey={'1'}
        className={getClassName(1)}
        onClick={() => onClick(1)}
        title={intl.formatMessage({ id: 'faq.h6_description' })}
      >
        Методология была разработана в рамках проекта «Оценка информационных интернет-порталов» медиа-экспертами Ионом Бундуки и Петру Маковеем. Проект был внедрен в 2017 году при финансовой поддержке Фонда Сороса в Молдове. Центр независимой журналистики оценил порталы при финансовой поддержке Посольства США в рамках проекта «Мониторинг социальных сетей на выборах». Эксперты-координаторы: Ион Бундуки, Ина Греждяну, Надин Гогу. 
        <br />
        <br />
        Методология состоит из следующих компонентов оценивания:
        <br />
        &nbsp;1) Наличие достаточных идентификационных данных информационных порталов;
        <br />
         &nbsp;2) Качество медиаконтента информационных порталов;
        <br />
         &nbsp;3) Степень информационной доступности данных сайтов.
        <br />
        <br />
        Результаты мониторинга и оценки позволяют составить предложения и рекомендации по улучшению деятельности информационных порталов в интересах их пользователей.
        <br /> <br />
        Достаточность объема размещенной информации об идентификационных данных необходима для устранения подозрений, которые могут возникнуть у пользователя, для повышения интерактивности (важная функция для онлайн-СМИ). И, наконец, будучи более открытым по отношению к пользователю - укрепляется доверие к информационным порталам. Доверие же является одним из наиболее существенных факторов, влияющих на мотивацию профессиональной деятельности.
        <br /> <br />
        Качественный медиаконтент способствует надлежащему, правдивому и честному информированию пользователей, в отличие от предосудительных практик дезинформации и манипуляций посредством псевдоинформационных порталов.
        <br /> <br />
        Доступность запрашиваемой читателем информации свидетельствует о том, что владельцы портала заботятся об удобстве и времени пользователя.
        <br /> <br />
        Оценка этих трех компонентов осуществляется посредством метода анализа информационных порталов. Эта методика включает оценку содержания портала, подлежащего мониторингу в режиме онлайн, с целью определения наличия или отсутствия количественных и качественных характеристик, согласно методологии. Эффективность этого метода обеспечивается максимальным сходством с обычными способами поиска информации, которые использует рядовой пользователь.
        <br /> <br />
        Оценка содержания порталов осуществляется путем сравнения степени соответствия юридическим и деонтологическим требованиям, приличествующим традиционным медиа-источникам. Правило учитывает положительный опыт в стране и за рубежом. Таким образом, оценка в рамках мониторинга производится по определенным критериям.
        <br /> <br />
        Значение результата мониторинга:
        <br />
        &nbsp;&ndash; &nbsp;степень соответствия (числовое значение) требованиям, подвергнутым оценке информационных порталов;
        <br />
        &nbsp;&ndash; &nbsp;степень доверия.
      </FAQAccordion>
      {/* Principii de baza ale monitorizarii */}
      <FAQAccordion
        eventKey={'2'}
        className={getClassName(2)}
        onClick={() => onClick(2)}
        title={intl.formatMessage({ id: 'faq.h6_principles' })}
      >
        <b>Мониторинг основывается на следующих главных принципах:</b>
        <br />
        &nbsp;&ndash; &nbsp;Принцип открытости: описание методики мониторинга и его результатов публикуется для всеобщего сведения.
        <br />
        &nbsp;&ndash; &nbsp;Принцип независимости: результаты мониторинга зависят исключительно от оценок его авторов, независимых друг от друга экспертов и от любых других факторов влияния.
        <br />
        &nbsp;&ndash; &nbsp;Принцип объективности: результаты мониторинга отражают текущую ситуацию исследуемой темы и не зависят от воли проводивших исследование лиц. Эксперты, участвующие в мониторинге, обладают соответствующими квалификациями и знанием.
        <br />
        <br />
        Мониторинг можно использовать в целях осуществления социального контроля за соответствием деятельности информационных порталов. Регулярный мониторинг позволяет отслеживать эволюцию или инволюцию в этой сфере интернет-СМИ. Результаты мониторинга позволяют выработать подходы и методы решения проблемных ситуаций.
      </FAQAccordion>
      {/* Scopul monitorizării */}
      <FAQAccordion
        eventKey={'3'}
        className={getClassName(3)}
        onClick={() => onClick(3)}
        title={intl.formatMessage({ id: 'faq.h6_goal' })}
      >
        Цель мониторинга - определение степени доверия к каждому субъекту после его оценки, проведенной в соответствии с критериями методологии мониторинга.
      </FAQAccordion>
      {/* Obiectul monitorizării */}
      <FAQAccordion
        eventKey={'4'}
        className={getClassName(4)}
        onClick={() => onClick(4)}
        title={intl.formatMessage({ id: 'faq.h6_object' })}
      >
        Объектом мониторинга является соответствие содержания информационных порталов ряду требований юридического и деонтологического толка, отраженных в действующих нормативных актах, в том числе:
        <br />
        &ndash;&nbsp;<a href="http://lex.justice.md/viewdoc.php?action=view&view=doc&id=311759&lang=2" target="_blank" rel="noreferrer">
          Закон о доступе к информации
        </a>.
        <br />
        &ndash;&nbsp;<a href="http://lex.justice.md/viewdoc.php?action=view&view=doc&id=335145&lang=2" target="_blank" rel="noreferrer">
          Закон о свободе выражения мнения
        </a>.

        <br />
        &ndash;&nbsp;<a href="https://consiliuldepresa.md/upload/%D0%94%D0%B5%D0%BE%D0%BD%D1%82%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BA%D0%BE%D0%B4%D0%B5%D0%BA%D1%81%20%D1%81%20%D0%B6%D1%83%D1%80%D0%BD%D0%B0%D0%BB%D0%B8%D1%81%D1%82%D0%B0%20%D0%A0%D0%B5%D1%81%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D0%BA%D0%B8%20%D0%9C%D0%BE%D0%BB%D0%B4%D0%BE%D0%B2%D0%B0%202019.pdf" target="_blank" rel="noreferrer">
          Деонтологический кодекс журналиста Республики Молдова
        </a>.
      </FAQAccordion>
      {/* Subiectul monitorizării */}
      <FAQAccordion
        eventKey={'5'}
        className={getClassName(5)}
        onClick={() => onClick(5)}
        title={intl.formatMessage({ id: 'faq.h6_subject' })}
      >
        <br />
        Предметом мониторинга является один, несколько или все информационные порталы в Республике Молдова, которые считаются частью онлайн-СМИ, функционирующих на момент мониторинга и объявления результатов.
        <br />
        Предметом мониторинга считается:
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          любой автономный сайт, который содержит журналистские произведения (новости, репортажи, интервью, комментарии, расследования и т. д.), выходящие не реже одного раза в месяц;
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          любой сайт-клон, имитирующий периодические издания (газет и журналов);
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          любой гибридный сайт периодических изданий (помимо материалов периодического издания на сайте размещается и собственное содержание);
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          любой сайт какого-либо телеканала или радиостанции, если они опубликовывают что-то;
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          полный текст контента, транслируемого по радио / телевидению, плюс текст контента, выполненный отдельно для сайта;
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          текст наиболее важного контента, транслируемого по радио / телевидению, по усмотрению сайта, плюс текст контента, сделанный отдельно для сайта;
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          только текст некоторого содержания, созданного для сайта;
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          любой клонированный сайт с содержанием различного рода, используемый в сомнительных целях.
        </span>
      </FAQAccordion>
      {/* Conținuturile monitorizate */}
      <FAQAccordion
        eventKey={'6'}
        className={getClassName(6)}
        onClick={() => onClick(6)}
        title={intl.formatMessage({ id: 'faq.h6_content' })}>
        Содержанием предметов мониторинга считается:
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          помещенные в одном из разделов меню сайта (политика, экономика, социальная сфера и т. д.);
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          представляющие один или несколько журналистских жанров (новости, интервью, репортажи, расследования, длительные чтения и т. д.);
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          требующие особой точности (новости, описывающие конфликты, электоральные новости, те, в которых главные лица &ndash; это дети или люди с особыми потребностями и т. д.);
        </span>
        <br />
        <span>
          <img src="/images/check_3.svg" className="mr-2" />
          все материалы.
        </span>
      </FAQAccordion>
      {/* Criterii de monitorizare */}
      <FAQAccordion
        eventKey={'7'}
        className={getClassName(7)}
        onClick={() => onClick(7)}
        title={intl.formatMessage({ id: 'faq.h6_criteria' })}>
        <p>Мониторинг информационных порталов осуществляется по следующим критериям:
          <br /> <br /> <strong>1.&nbsp;Доступность идентификационных данных</strong>
          <br /> <strong><em>Обоснование критерия:&nbsp;</em></strong>&nbsp;измеряет степень открытости и прозрачности портала по отношению к его пользователям. СМИ продвигают принцип открытости, как демократическую ценность, в том числе посредством прозрачности своей собственной деятельности. Честному СМИ, как и любой другой социальной организации, нечего скрывать. Сам факт утаивания определенной информации какой-либо общественной организацией вызывает подозрения и неизбежно подрывает доверие к этому институту. Фактически, без доверия исчезает мотивация для осуществления его деятельности.
          <br /> <br /> <strong><em>Критерий устанавливает &nbsp;</em></strong>наличие или отсутствие информации о портале, таких как: год основания, наименование учредителя / имя владельца, фактический адрес и контактные данные, состав организации, спонсоры портала и т. д.
          <br /> <br /> <strong><em>Критерий &nbsp;</em></strong>имеет следующие числовые значения<br /> 5 баллов &ndash; данные доступны и функционируют;
          <br /> 0 баллов &ndash; данные доступны, но не работают; данные недоступны.
          <br /> <br /> <strong>Источники для проверки:&nbsp;</strong>Проверяющие мониторят разделы / подразделы о презентации портала, расположенные вверху, внизу или по сторонам на первой странице (домашней страницы) портала. Разделы / подразделы могут называться по-разному: «О нас», «О проекте», «Кто мы», «Контакты», «Наша команда» и т. д. Проверяющие также изучают реальны ли контактные данные, в случае если они доступны на сайте. Идентификационные данные считаются действительными, если один из администраторов портала ответил или был обнаружен за период мониторинга.
          <br /> <br /> <strong>2.&nbsp;Положение о редакционной политике </strong>
          <br /> <strong><em>Обоснование критерия: &nbsp;</em></strong>измеряет степень открытости, предсказуемости и ответственности портала по отношению к своим пользователям. Заявление о редакционной политике приветствуется, как минимум по двум причинам: пользователь, ознакомившись с ней, может принять решение о том, - будет ли он посещать этот портал, будет ли с ним консультироваться  и использовать; портал берет на себя обязательства перед пользователями и должен их выполнять. Заявление добавляет уверенности пользователю и повышает доверие к порталу.
          <br /> <br /> <strong><em>Критерий устанавливает &nbsp;</em></strong>наличие заявления о редакционной политике. Как правило, редакционная политика &ndash; это стратегия редакции, своего рода долгосрочный ориентир, диктующий определенное видение профессиональных, социальных, моральных, культурных, политических, экономических ценностей и т. д. Кроме того, редакционная политика априори, в некотором роде отвечает на вопросы или замешательства, которые могут возникнуть у пользователя. Например, экономический портал будет сортировать свои новости иначе, чем это делает женский портал. Редакционная политика выражает или должна выражать разницу между бизнес-порталом и веб-сайтом для скандалов; порталом левого политического толка и тем, который ориентирует на правую политику и т. д. Таким образом, важно, чтобы пользователю сообщили о редакционной политике, которую выбрал портал. Исходя из этого, предполагается, что редакция будет ей следовать.
          <br /> <br /> <strong><em>Критерий </em></strong>&nbsp;имеет следующие числовые значения:
          <br /> 5 баллов &ndash; есть заявление редакционной политики;
          <br /> 0 баллов &ndash; нет заявления о редакционной политике.
          <br /> <br /> <strong>3.&nbsp;Профессиональные нормы, принятые публично</strong>
          <br /> <strong><em>Обоснование критерия:&nbsp;</em></strong>&nbsp;он измеряет степень ответственности портала за публичное принятие профессиональных / деонтологических норм и, наконец, степень ответственности по отношению к его пользователям. Кроме того, портал проявляет, таким образом, корпоративный дух профессионального цеха, выступающий за качественный подход к делу и коллективную ответственность за соблюдение профессиональной этики.
          <br /> <br /> <strong><em>Критерий устанавливает,&nbsp; </em></strong>если портал подписал или не подписал Деонтологический кодекс журналиста Республики Молдова или любой другой документ подобного рода международного или национального характера.
          <strong><br /> <br /> <em>Критерий имеет следующие числовые значения:</em></strong>
          <br /> <strong>5 баллов &ndash;</strong> принял публично профессиональные нормы;
          <br /> <strong>0 баллов &ndash;</strong> не принял публично профессиональных норм.
          <br /> <br /> <strong>4.&nbsp;Качество медиапродукции</strong>
          <br /> <strong><em>Обоснование критерия: &nbsp;</em></strong>определяет качество медиапродукции, без которого не может появиться доверие. Естественная миссия СМИ - предоставлять качественные медиапродукты, а это означает, что портал должен преследовать следующие цели: информирование, обучение или развлечение пользователя, соблюдая требования юридических и деонтологических норм. Если портал преследует другую цель, то скорее всего он манипулирует пользователем. Это самый ценный критерий, которому должно соответствовать любое медиа-учреждение. Если портал не соответствуют данному критерию, то он не может претендовать на доверие пользователя, даже если он соответствует всем остальным критериям. Вот почему этот критерий более весомый, учитывая предоставленные оценки, по сравнению с остальными четырьмя критериями, вместе взятыми.
          <br /> <strong><em>Критерий определяет,&nbsp;</em></strong><strong>&nbsp;</strong>отвечает ли медиапродукт ряду требований, включая:</p>
        <p><br /> <strong>4.1 Тема актуальна и представляет общественный интерес:</strong>
          <br /> <strong><em>Информация актуальна и представляет общественный интерес &ndash; 6 баллов,</em></strong>
          <br /> <strong><em>Информация представляет общественный интерес, но не актуальна &ndash; 3 балла,</em>
          </strong><br /> <strong><em>Информация актуальна, но не представляет общественного интереса &ndash; 0 баллов,</em></strong>
          <br /> <strong><em>Информация не представляет общественного интереса и не актуальна &ndash; минус 3 балла,</em></strong>
          <br /> <strong><em>Информация не представляет общественного интереса, не является актуальной и продвигает определенные политические течения (теории заговора, оккультные практики, антиценности (разжигание ненависти, дискриминацию и т. д.), чуждые ценности, фальшивые новости, слухи и т. д.) &ndash; минус 6 баллов</em></strong><strong>
            <br /><br /> <strong>4.2 Портал ссылается на источники:&nbsp;</strong></strong>
          <br /> <strong><em>Источники разнообразны, достоверны (независимые, документальные или живые, конкретные и релевантные), представляют все стороны конфликта &ndash; 8 баллов</em></strong>
          <br /> <strong><em>Источники заслуживают доверия, они представляют обе стороны конфликта, но приводятся и такие источники, которые говорят что-то наподобие этого: &ldquo;согласно независимым экспертам&rdquo; &ndash; 4 балла</em></strong>
          <br /> <strong><em>Источники достоверные, но не разнообразные, представляют лишь одну сторону, в том числе в конфликтных ситуациях &ndash; 0 баллов</em></strong>
          <br /> <strong><em>Источники представляют обе стороны конфликта, но не надежные (анонимные эксперты, нерелевантные источники)  &ndash; минус 4 балла</em></strong>
          <br /> <strong><em>Недостоверные источники или отсутствие цитаты источника &ndash; минус 8 баллов</em></strong>
          <br /><br /> <strong>4.3 Тема написана точно, а предоставленные сведения были проверены на предмет подтверждения достоверности информации:&nbsp;
            </strong><strong><em>Заголовок соответствует содержанию, данные точны, использованные изображения дополняют тему, тема позаимствована, проверена на достоверность &ndash; 8 баллов</em></strong>
          <br /> <strong><em>Заголовок соответствует содержанию, данные точны, изображения дополняют тему, тему позаимствовали, но не проверили на достоверность &ndash; 4 балла</em></strong>
          <br /> <strong><em>Заголовок соответствует содержанию, изображения дополняют тему, но данные неточны, достоверность темы не проверена &ndash; 0 баллов</em></strong>
          <br /> <strong><em>Заголовок не сенсационный, но не соответствует содержанию, данные не точны, фотографии искажают информацию, тема не проверена на достоверность &ndash; минус 4 балла</em></strong>
          <br /> <strong><em>Заголовок не соответствует содержанию, заголовок сенсационный, фотографии искажают информацию, тема основана на слухах, тема написана недобросовестно &ndash; минус 8 баллов</em></strong>
          <br /> <br /> <strong>4.4 Тема написана беспристрастно и непредвзято: <em>факты отделены от мнений, для описания ситуаций не используются прилагательные, автор объективен &ndash; 8 баллов</em></strong>
          <br /> <strong><em>В целом, автор объективен, но иногда использует прилагательные для описания определенных ситуаций &ndash; 4 балла</em></strong>
          <br /> <strong><em>Факты не отделены от мнений / часто используются прилагательные для описания определенных ситуаций &ndash; 0 баллов</em></strong>
          <br /> <strong><em>Факты неотделимы от мнений, навешивают ярлыки, автор тенденциозен и субъективен &ndash; минус 4 балла</em></strong>
          <br /> <strong><em>Факты не отделены от суждений, вешаются ярлыки, автор тенденциозен и субъективен, очевиден злой умысел &ndash; минус 8 баллов</em></strong>
          <br /><br /> <strong>4.5 Авторство материалов: </strong>
          <br /> <strong><em>Собственные авторские материалы &ndash; 6 баллов</em></strong>
          <br /> <strong><em>Собственные материалы за авторством редакции &ndash; 3 балла</em></strong>
          <br /> <strong><em>Материалы позаимствованы согласно деонтологическим нормам, со ссылкой на первоисточники &ndash; 0 баллов.</em></strong>
          <br /> <strong><em>Материалы полностью позаимствованы, без соблюдения деонтологических норм, со ссылкой на первоисточники &ndash; минус 3.</em></strong>
          <br /> <strong><em>Материалы вышли без указания автора, ссылка на первоисточник отсутствует &ndash; минус 6</em></strong></p>
        <p>В итоге, критерий имеет следующие числовые значения: 
        <br /> 36 баллов &ndash; качество соответствует всем требованиям
        <br /> 35,9 &ndash; 26 баллов &ndash; качество отвечает большинству требований
        <br /> 25,9 &ndash; 16 баллов &ndash; качество во многом соответствует требованиям
        <br /> 15,9 &ndash; 1 балл &ndash; качество в малой степени соответствует требованиям
        <br /> 0 &ndash; минус 15,9 балла &ndash; качество не во многом соответствует требованиям
        <br /> Минус 25,9 &ndash; минус 16 баллов &ndash; качество не отвечает большинству требований
        <br /> Минус 36 &ndash; минус 26 баллов &ndash; качество не соответствует требованиям, портал действует злонамеренно и занимается псевдожурналистикой.
        <br /> <br /> <strong>5.&nbsp;Удобство навигации (юзабилити)</strong>
          <br /> <strong><em>Обоснование критериев: &nbsp;</em></strong>измеряет степень удобства поиска релевантной информации на портале. Соответствующая информация для целей данной методологии означает идентификационные данные, заявление о редакционной политике, общепринятые деонтологические нормы, медиа-продукты. Актуальная информация теряет свою ценность, если до нее трудно добраться или же она становится совсем нерелевантной, если ее нельзя открыть.
        <br /> <strong><em>Критерий определяет &nbsp;</em></strong>количество переходов от домашней страницы портала к искомой / запрошенной информации.
        <br /> <br /> <strong><em>Критерий &nbsp;</em></strong>имеет следующие числовые значения:<br /> 5 баллов &ndash; информация стала доступна через количество переходов от 1 до 4;
        <br /> 0 баллов &ndash; информация доступна через количество переходов больше 4.</p>
      </FAQAccordion>
      {/* Deducerea indicelui de credibilitate al site-urilor monitorizate */}
      <FAQAccordion
        eventKey={'8'}
        className={getClassName(8)}
        onClick={() => onClick(8)}
        title={intl.formatMessage({ id: 'faq.h6_deduction' })}>
        <p>Индекс доверия информационных порталов рассчитывается путем суммирования баллов, присвоенных каждому критерию.
        <br /> Максимальный балл: <strong>56 баллов.</strong>
          <br /> Если портал набрал от 46 до 56 баллов, пользователи могут ему доверять. 
        <br /> Если портал набрал от 36 до 45,9 балла, он соответствует по большому счету критериям надежности, но есть, к чему стремиться (возможности для улучшения).
        <br /> Если портал набрал от 26 до 35,9 балла, он соответствует некоторым критериям надежности, и его пользователи должны проявлять осторожность.
        <br /> Если портал набрал от 16 до 25,9 балла, он соответствует малому количеству критериев надежности.
        <br /> Если портал набрал менее 16 баллов, пользователи не могут ему доверять.
        <br /> В зависимости от набранных баллов, пользователи приложения смогут увидеть рейтинг сайта после того, как они внесут название интересующего их портала. Каждому сайту выносится вердикт с определенными пояснениями:
        <br /> <br /> <strong>ВНИМАНИЕ: ПОРТАЛ НЕ ЯВЛЯЕТСЯ ИНФОРМАЦИОННЫМ!</strong>
          <br /> <br />В результате мониторинга указанный портал набрал от <strong>минус 36 до 0 баллов</strong>. Указанный портал не является информационным порталом и может быть использован для целей, отличных от передачи информации, в том числе для манипулирования и передачи ложных новостей. Мы не рекомендуем использовать этот портал в качестве источника информации. Чтобы не стать  жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>ПОРТАЛ НЕ СООТВЕТСТВУЕТ МИНИМАЛЬНЫМ КРИТЕРИЯМ ДОВЕРИЯ</strong>
          <br /> <br /> В результате мониторинга указанный портал набрал от <strong>0 до 16 баллов</strong>. Портал не соответствует минимальным критериям доверия, описанными экспертами Центра независимой журналистики (CJI) в методологии мониторинга. Мы не рекомендуем использовать этот портал в качестве источника информации. Чтобы не стать  жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>ПОРТАЛ СООТВЕТСТВУЕТ  НЕБОЛЬШОМУ КОЛИЧЕСТВУ МИНИМАЛЬНЫХ КРИТЕРИЙ ДОВЕРИЯ</strong>
          <br /> <br /> В результате мониторинга указанный портал набрал от <strong>16 до 26 баллов</strong>. Портал соответствует небольшому количеству критериев достоверности, описанных экспертами Центра независимой журналистики (CJI) в методологии мониторинга, что не позволяет нам рекомендовать его вам в качестве надежного источника информации. Чтобы не стать  жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>ПОРТАЛ СООТВЕТСТВУЕТ НЕКОТОРЫМ МИНИМАЛЬНЫМ КРИТЕРИЯМ ДОВЕРИЯ</strong>
          <br /> <br /> В результате мониторинга указанный портал набрал от <strong>26 до 36 баллов</strong>. Портал соответствует минимальным критериям доверия, описанными экспертами Центра независимой журналистики (CJI) в методологии мониторинга, но есть еще возможности для улучшения. Некоторые опубликованные материалы недостаточно хорошо задокументированы и видно несколько предвзятое отношение. Рекомендуем получать информацию и из других источников. Чтобы не стать жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>ПОРТАЛ СООТВЕТСТВУЕТ В БОЛЬШЕЙ МЕРЕ МИНИМАЛЬНЫМ КРИТЕРИЯМ ДОСТОВЕРНОСТИ</strong>
          <br /> <br /> В результате мониторинга указанный портал набрал от <strong>36 до 46 баллов</strong>. Портал во многом соответствует минимальным критериям достоверности, описанным экспертами Центра независимой журналистики (CJI) в методологии мониторинга. Чтобы не стать  жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>ПОРТАЛ СООТВЕТСТВУЕТ ВСЕМ МИНИМАЛЬНЫМ КРИТЕРИЯМ ДОВЕРИЯ</strong><br /> 
          <br />По результатам мониторинга указанный портал набрал от <strong>46 до 56 баллов</strong>. Портал соответствует всем минимальным критериям доверия, описанным экспертами Центра независимой журналистики (CJI) в методологии мониторинга. Чтобы не стать  жертвой манипуляции информацией, мы рекомендуем вам получать информацию из достоверных источников (см. <a href="/ru/media-agencies/">рейтинг доверия сайтов, подвергнутых мониторингу</a>).
        <br /> <br /> <strong>Рейтинг доверия сайтов, подвергнутых мониторингу </strong>
          <br /> <br /> Рейтинг доверия информационных порталов определяется общим баллом в порядке убывания. В рейтинг вошли порталы, набравшие более 20 баллов.
        <br /> <br /> Рейтинг доверия выставляется приложением, разработанным для этой цели организатором мониторинга.</p>
      </FAQAccordion>
    </>
  )
}

export default FAQRu
