import React from "react"
import { Accordion, Card } from "react-bootstrap"

const FAQAccordion = (props: { children: any, className: string, eventKey: any, title: string, onClick: any }): JSX.Element => {
  const { className, eventKey, children, title, onClick } = props
  return (<Accordion key={eventKey}>
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        className="d-flex justify-content-between bg-white p-4"
        role="button"
        onClick={onClick}
      >
        <h6 className="metodologia-page-h6">{title}</h6>
        <img
          src="/images/arrow-down.svg"
          alt="arrow"
          className={className}
        />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="px-4">
          <p className="metodologia-page-paragraph">
            {children}
          </p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  </Accordion>)
}
export default FAQAccordion