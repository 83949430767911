import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container, Row } from "react-bootstrap"
import FAQRo from "../components/faqro"
import FAQRu from "../components/faqru"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"

const FaqPage = (): JSX.Element => {
  const intl = useIntl()

  return <Layout>
    <SEO
      title={intl.formatMessage({ id: 'navbar.metodologia' })}
      description={intl.formatMessage({ id: 'faq.subtitle' })}
    />
    <Header
      title={intl.formatMessage({ id: 'faq.title' })}
      subtitle={intl.formatMessage({ id: 'faq.subtitle' })}
    />
    <Row className="bg-white py-5" noGutters>
      <Container className="py-5">
        {intl.locale === 'ru' ? <FAQRu /> : <FAQRo />}
      </Container>
    </Row>
  </Layout>
}

export default FaqPage
