import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import FontAwesome from 'react-fontawesome'


const SearchForm = ({ searchTerm, onChange, onSubmit }) => {
  const intl = useIntl()

  return (
    <form name="search-form" className="mt-5" onSubmit={onSubmit}>
      <div className="search-input-container m-auto">
        <button onClick={onSubmit} className="search-button" disabled={!searchTerm}>
          <FontAwesome name="search" />
        </button>
        <input
          type="text"
          className="search-input"
          maxLength={256}
          value={searchTerm}
          onChange={onChange}
          name="name"
          placeholder={intl.formatMessage({ id: 'search.placeholder' })}
        />
      </div>
    </form>
  )
}

export default SearchForm
